div.product-row div.product-name {
  font-weight: bold;
  font-size: 1.2rem;
}
div.product-row div.product-code, div.product-row div.product-ean {
  font-size: 0.8rem;
  color: #888888;
  line-height: 1rem;
}
div.product-row div.product-order-info {
  margin-top: 30px;
  color: #888888;
}
div.product-row div.product-description {
  width: 500px;
  padding-left: 10px;
  padding-top: 5px;
  border-radius: 5px;
}
div.product-row.inOrder div.product-description {
  background: #ceffce;
}
div.product-row.focused div.product-description {
  background: rgba(199, 249, 255, 0.5);
}

div#product-row-big-image {
  position: fixed;
  right: 100px;
  top: 100px;
  padding: 50px;
  background: white;
  border: 1px solid #888888;
  border-radius: 10px;
}

