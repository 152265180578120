div.product-row {
  div.product-name {
    font-weight: bold;
    font-size: 1.2rem;
  }
  div.product-code, div.product-ean {
    font-size: 0.8rem;
    color: #888888;
    line-height: 1rem;
  }
  div.product-order-info {
    margin-top: 30px;
    color: #888888;
  }
  div.product-description {
    width: 500px;
    padding-left: 10px;
    padding-top: 5px;
    border-radius: 5px;
  }
  &.inOrder {
    div.product-description {
      background: #ceffce;;
    }
  }
  &.focused {
    div.product-description {
      background: rgba(199, 249, 255, 0.5);
    }
  }
}
div#product-row-big-image {
  position: fixed;
  right: 100px;
  top: 100px;
  padding: 50px;
  background: white;
  border: 1px solid #888888;
  border-radius: 10px;
}
